<template>
    <div>
        <a :href="getFacebookShareLink()" target="_blank" :class="'social-icon si-facebook' + (iconClass ? ' ' + iconClass : '')">
            <i class="icon-facebook"></i>
            <i class="icon-facebook"></i>
        </a>
        <a :href="getTwitterShareLink()" target="_blank" :class="'social-icon si-twitter' + (iconClass ? ' ' + iconClass : '')">
            <i class="icon-twitter"></i>
            <i class="icon-twitter"></i>
        </a>
        <a :href="getLinkedInShareLink()" target="_blank" :class="'social-icon si-linkedin' + (iconClass ? ' ' + iconClass : '')">
            <i class="icon-linkedin"></i>
            <i class="icon-linkedin"></i>
        </a>
        <a :href="getWhatsAppShareLink()" target="_blank" :class="'social-icon si-whatsapp' + (iconClass ? ' ' + iconClass : '')">
            <i class="icon-whatsapp"></i>
            <i class="icon-whatsapp"></i>
        </a>
        <a :href="getPinterestShareLink()" target="_blank" :class="'social-icon si-pinterest' + (iconClass ? ' ' + iconClass : '')">
            <i class="icon-pinterest"></i>
            <i class="icon-pinterest"></i>
        </a>
        <!--
        <a href="#" class="social-icon si-borderless si-email3">
            <i class="icon-email3"></i>
            <i class="icon-email3"></i>
        </a>
        -->
        
    </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { utils } from '@fwk-client/router/utils';

@Component({
  components: {}
})
export default class ShareLinks extends Vue {

    @Prop({
        type: String,
        required: false,
    }) readonly title!: string

    @Prop({
        type: String,
        required: false,
    }) readonly iconClass!: string // ex: si-borderless

	currentURL:string|undefined = undefined;

	created() {
		const baseURL = (this.$store.state.server && this.$store.state.server.baseURL) ? this.$store.state.server.baseURL : null;
		const route = this.$store.state.route;
        this.currentURL = baseURL + utils.getRoutePathWithLanguage(route);
	}
	
	get currentLanguageCode() {
		return this.$store.state.languages.currentLanguageCode;
	}

	getFacebookShareLink() {
		var url = "https://www.facebook.com/sharer/sharer.php?u=" + this.currentURL;
		return url;
	}

	getTwitterShareLink() {
        var text = (this.title && this.title != "") ? (encodeURI(this.title) + " : ") : "";
		var url = "https://twitter.com/intent/tweet?text=" + text + this.currentURL;
		return url;
	}

    getLinkedInShareLink() {
        var url = "https://www.linkedin.com/sharing/share-offsite/?url="+this.currentURL;
		return url;
    }

	getWhatsAppShareLink() {
		var url = "https://api.whatsapp.com/send?text=" + this.currentURL;
		return url;
	}

	getPinterestShareLink() {
        var text = (this.title && this.title != "") ? ("&media=&description=" + this.title) : "";
		var url = "https://pinterest.com/pin/create/button/?url=" + this.currentURL + text;
		return url;
	}
	
}
</script>