<template>
	<Section :options="sectionOptions" class="actualite">

		<p class="back-to-actualites">
			<router-link :to="{ name : actualitesPageName, params : { lang : currentLanguageCode }}">
				<i class="icon-angle-left1"></i>
				{{ $t("actualites.back-to-actualites") }}
			</router-link>
		</p>

		<div class="single-post mb-0" v-if="post">

			<!-- Single Post
			============================================= -->
			<div class="entry clearfix">

				<!-- Entry Title
				============================================= -->
				<div class="entry-title">
					<h1>{{ getLocalizedText(post.title) }}</h1>
				</div><!-- .entry-title end -->

				<!-- Entry Meta
				============================================= -->
				<div class="entry-meta">
					<ul>
						<li><i class="icon-calendar3"></i> {{ formatDate(post.date) }}</li>
						<!-- <li><a href="#"><i class="icon-user"></i> admin</a></li>
						<li><i class="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
						<li><a href="#"><i class="icon-comments"></i> 43 Comments</a></li>
						<li><a href="#"><i class="icon-camera-retro"></i></a></li> -->
					</ul><!-- .entry-meta end -->
				</div>

				<!-- Entry Image
				============================================= -->
				<div v-if="post.picture" class="entry-image bottommargin">
					<img :src="getStaticURLFromPath(post.picture.original.path)" :alt="getLocalizedText(post.title)">
				</div><!-- .entry-image end -->

				<!-- Entry Content
				============================================= -->
				<div class="entry-content mt-0">

					<LocalizedContent :localizedContent="post.content.localizedText" ></LocalizedContent>
					<!-- Post Single - Content End -->

					<!-- Tag Cloud
					============================================= -->
					<!--
					<div class="tagcloud clearfix bottommargin">
						<a href="#">general</a>
						<a href="#">information</a>
						<a href="#">media</a>
						<a href="#">press</a>
						<a href="#">gallery</a>
						<a href="#">illustration</a>
					</div>-->
					<!-- .tagcloud end -->

					<!-- Post Single - Share
					============================================= -->
					<div class="si-share d-flex align-items-center topmargin">
						<span>{{ $t("actualites.post.share") }}</span>
						<ShareLinks :title="getLocalizedText(post.title)" ></ShareLinks>
					</div><!-- Post Single - Share End -->

				</div>
			</div><!-- .entry end -->
		
		</div>
		<p v-else>
			{{ $t("actualites.error.not-found") }}
		</p>

		<p class="back-to-actualites">
			<router-link :to="{ name : actualitesPageName, params : { lang : currentLanguageCode }}">
				<i class="icon-angle-left1"></i>
				{{ $t("actualites.back-to-actualites") }}
			</router-link>
		</p>
	</Section>
</template>

<style>

/* We add top margin between the content and the back to news link */
.actualite.container > div {
	margin-top:20px;
}

.actualite .entry-title h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.actualite .entry-image img {
	max-height:600px;
	width:auto;
}

.actualite .entry-content h2 {
	font-size:1.1rem;
	margin-bottom: 15px;
}

.actualite .entry-content .media {
	display:block;
	max-width:800px;
}

.actualite .entry-content h3 {
	margin-top: 30px;
	margin-bottom: 20px;
}

.actualite .entry-content p {
	margin-bottom: 15px;
}

.actualite .entry-content ul {
	margin-left: 20px;
}

/* We add some margin between share title and icons */
.actualite .si-share span {
    margin-right: 10px;
}

.actualite .si-share .social-icon {
    border-radius: 3px;
    margin: 0 5px 0 0
}

</style>

<script lang="ts">


import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import { getApp, useStore } from '@fwk-client/utils/vue-3-migration';
import { useRoute, useRouter, useLink, onBeforeRouteUpdate, onBeforeRouteLeave } from '@fwk-node-modules/vue-router/composables'
import { formatDay, FormatDateType, formatMonth as genericFormatMonth } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import ShareLinks from '@root/src/client/components/panels/input/ShareLinks.vue';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import { getters as blogGetters } from '@fwk-client/modules/blog/stores';


/** @cmsOptions */
export interface PostDetailsOptions {
  /** @cmsType CmsText */
  postPath?:CmsText,
  /** @cmsType CmsText */
  postPathParam?:CmsText,
  /** @cmsType CmsText */
  actualitesPageName?:CmsText,
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface PostDetailsLabels {
   
}

/** @cmsSlots */
export interface PostDetailsSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<PostDetailsOptions>,
        labels: {
          type: Object as PropType<PostDetailsLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<PostDetailsSlots>
    },
    components: {
    	ShareLinks,
	  	LocalizedContent,
	  	Section
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const store = useStore();
		const $route = useRoute();

        const computedOptions:PostDetailsOptions = {
            sectionOptions : {section:false},
            postPathParam:"postPath",
			actualitesPageName:"nos-actualites",
			...props.options
		};

		const post:Ref<any> = ref(undefined);

		// We get the post path
		var postPath:string|null = null;
		if(computedOptions.postPath && computedOptions.postPath != "") {
			postPath = computedOptions.postPath
		}
		else if($route.params && $route.params[computedOptions.postPathParam!]) {
			postPath = $route.params[computedOptions.postPathParam!];
		}
		
		// We retrieve the post
		if(postPath) {
			post.value = blogGetters.$blog_post.get.call(app).call(app, postPath);
		}

		const currentLanguageCode = computed(() => {
			return store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
		})

		const formatMonth = (date:Date) => {
			return genericFormatMonth(date, currentLanguageCode.value, {type:FormatDateType.LONG});
		}

		const formatDate = (date:Date) => {
			return formatDay(date, currentLanguageCode.value)
		}

        return {
            sectionOptions:computedOptions.sectionOptions,
			actualitesPageName:computedOptions.actualitesPageName,
			currentLanguageCode,
            formatMonth,
			formatDate,
			post
        }

    }
})
</script>