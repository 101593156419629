<template>
	<div class="page-actualite content">

			<div class="content-wrap">

				<PostDetails></PostDetails>

			</div>

	</div>
</template>

<style>

</style>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PostDetails from '../cms/canvas/blog/PostDetails.vue';


@Component({
  components: {
	  PostDetails
  }
})
export default class Actualite extends mixins(GenericPage) {
	
}
</script>